<template>
  <document-search
    v-bind="$attrs"
    data-test="order-search"
    document="order"
    item-title="qtm_reference_number"
    :menu-props="menuProps"
    :style="style"
  >
    <template v-slot:item="{ item, props }">
      <slot name="item" :item="item" :props="props">
        <order-search-item v-bind="props" :order="item.value" />
      </slot>
    </template>
  </document-search>
</template>

<script>
import DocumentSearch from '@/components/search/document-search'
import OrderSearchItem from '@/components/search/order-search-item'

export default {
  name: 'order-search',
  components: { DocumentSearch, OrderSearchItem },
  props: {
    maxWidth: {
      type: [Number, String],
      default: undefined
    },
  },
  computed: {
    menuProps() {
      return { dataTest: 'order-search-menu', maxWidth: this.maxWidth, ...this.$attrs['menu-props'] }
    },
    style() {
      return this.maxWidth ? `max-width: ${this.maxWidth}px` : undefined
    },
  },
}
</script>
